import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/Seo';
import GeneralHero from '../components/GeneralHero';
import Cta from '../components/Cta';
import { Constrain, GeneralBody } from '../components/Containers';
import NotFound from '../components/NotFound';

// markup
export default function NotFoundPage() {
	return (
		<>
			<Seo
				title="404 Page Not Found - WPEForm"
				description="Check plans and pricings of WPEForm, for small to large WordPress Form business owners."
			/>
			<GeneralHero
				left={
					<StaticImage
						src="../images/404.png"
						alt="Page Not Found"
						placeholder="tracedSVG"
					/>
				}
				right={
					<GeneralHero.Article>
						<GeneralHero.Title>Oops!!</GeneralHero.Title>
						<GeneralHero.Subtitle>
							Looks like you've stumbled upon something that doesn't exist. How
							about we offer you something?
						</GeneralHero.Subtitle>
						<Cta.Group size="regular" align="center-left-desktop">
							<Cta type="primary" to="/" size="regular">
								Home
							</Cta>
							<Cta type="ghost" to="/docs/" size="regular">
								Documentation
							</Cta>
						</Cta.Group>
					</GeneralHero.Article>
				}
			/>
			<Constrain>
				<GeneralBody id="compare-plans">
					<NotFound />
				</GeneralBody>
			</Constrain>
		</>
	);
}
